// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState: {
    list: [],
  },
  reducers: {
    getAnalysis(state, action) {
      state.list = action.payload
    },
    createAnalysis(state, action) {
      state.list = [...state.list, action.payload]
    },
  },
})

export const {getAnalysis, createAnalysis} = analysisSlice.actions
export default analysisSlice.reducer
