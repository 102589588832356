// ** React Imports
import {Suspense, lazy, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import {Provider} from 'react-redux'
import {store} from './redux/store'

// ** Intl & ThemeColors Context
import ability from './configs/acl/ability'
import {ToastContainer} from 'react-toastify'
import {AbilityContext} from './utility/context/Can'
import {ThemeContext} from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { getMsalConfig } from './auth/authConfig'
import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'

const AppWithAuthentication = ({ children}) => {
  const [instance, setInstance] = useState(null)
  useEffect(() => {
    const initializeMsal = async () => {
      const msalConfig = await getMsalConfig()
      const msalInstance = new PublicClientApplication(msalConfig)
      msalInstance.initialize().then(async() => {
        const activeAccount = msalInstance.getActiveAccount()
  
        if (!activeAccount) {
          const accounts = msalInstance.getAllAccounts()
          if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0])
          }
        }

        msalInstance.addEventCallback((event) => {
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const authenticationResult = event.payload
            const account = authenticationResult.account
            msalInstance.setActiveAccount(account)
          }
        })

        msalInstance.enableAccountStorageEvents()
        setInstance(msalInstance)
      })
    }

    initializeMsal()
  }, [])

  return (
    <>
      { instance && 
         <MsalProvider instance={instance}>
            { children }
         </MsalProvider>
      }
    </>
  )
}

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AppWithAuthentication>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
          </ThemeContext>
        </AbilityContext.Provider>
      </AppWithAuthentication>
    </Suspense>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
