import { LogLevel } from "@azure/msal-browser"
import { getAllConfig } from '@src/configs/config'

export const getMsalConfig = async() => {
  const config = await getAllConfig()
  const msalConfig = {
    auth: {
      clientId: config.MSAL_CLIENT_ID,
      authority: config.MSAL_AUTHORITY,
      redirectUri: config.MSAL_REDIRECT,
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      secureCookies: true,
      storeAuthStateInCookie: false
    },
    system: {
      windowHashTimeout: 9000,
      iframeHashTimeout: 9000,
      loadFrameTimeout: 9000,
      loggerOptions: {
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
            case LogLevel.Info:
              console.info(message)
            case LogLevel.Verbose:
              console.debug(message)
            case LogLevel.Warning:
              console.warn(message)
            default:
  
          }
        },
      },
    },
  }

  return msalConfig
}


export const loginRequest = {
  scopes: ['User.Read', 'group.Read.All']
}

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphGroupsEndpoint: 'https://graph.microsoft.com/v1.0/me/memberOf'
}
