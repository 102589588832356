export const getConfig = async (key) => {
  return await fetch(`${location.origin}/config.json`)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        return data[key]
      })
}

export const getAllConfig = async () => {
  return await fetch(`${location.origin}/config.json`)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        return data
      })
}