// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import users from './users'
import models from './models'
import areas from './areas'
import analysis from './analysis'
import prompts from './prompts'

const rootReducer = {
  auth,
  navbar,
  layout,
  users,
  models,
  areas,
  analysis,
  prompts,
}

export default rootReducer
