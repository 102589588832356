// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

export const reportsSlice = createSlice({
  name: 'models',
  initialState: {
    list: [],
    status: null,
  },
  reducers: {
    getModels(state, action) {
      state.list = action.payload
      state.status = 'success'
    },
    createModel(state, action) {
      state.list = [...state.list, action.payload]
      state.status = 'success'
    },
    deleteModel(state, action) {
      state.list = state.list.filter((model) => model.id !== action.payload)
      state.status = 'success'
    },
  },
})

export const {getModels, createModel, deleteModel} = reportsSlice.actions
export default reportsSlice.reducer
