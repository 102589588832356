// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

export const areasSlice = createSlice({
  name: 'areas',
  initialState: {
    list: [],
    status: null,
  },
  reducers: {
    getAreas(state, action) {
      state.list = action.payload
      state.status = 'success'
    },
    createArea(state, action) {
      state.list = [...state.list, action.payload]
      state.status = 'success'
    },
    updateArea(state, action) {
      state.list = state.list.map((area) => {
        return area.id === action.payload.id ? {...area, ...action.payload} : area
      })
      state.status = 'success'
    },
    deleteArea(state, action) {
      state.list = state.list.filter((area) => area.id !== action.payload)
      state.status = 'success'
    },
  },
})

export const {getAreas, createArea, deleteArea, updateArea} = areasSlice.actions
export default areasSlice.reducer
