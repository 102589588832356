// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import bicloudAxios from '../api/config/bicloudAxios'

// export const getUsers = createAsyncThunk('users/getUsers', async (userData, {rejectWithValue}) => {
//   try {
//     const r = await bicloudAxios.get('/admin/user/getAll')
//     return r.data
//   } catch (error) {
//     return rejectWithValue(error)
//   }
// })

export const createUser = createAsyncThunk('users/createUser', async (data) => {
  const r = await bicloudAxios.post('/admin/user/createUser', JSON.stringify(data))
  return r.data
})

export const editUser = createAsyncThunk('users/editUser', async (data) => {
  const r = await bicloudAxios.put(`/admin/user/updateUser/${data.userId}`, JSON.stringify(data))
  return r.data
})

export const editPersonalData = createAsyncThunk('users/editPersonalData', async (data) => {
  const r = await bicloudAxios.put('/user/update', JSON.stringify(data))
  return r.data
})

export const updatePassExpired = createAsyncThunk(
  'users/updatePassExpired',
  async (data, {rejectWithValue}) => {
    try {
      data = {actual_password: data.currentPassword, new_password: data.newPassword}
      const r = await bicloudAxios.put('/user/updatePassExpired', data)
      return r.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updatePass = createAsyncThunk('users/updatePass', async (data, {rejectWithValue}) => {
  try {
    data = {actual_password: data.currentPassword, new_password: data.newPassword}
    const r = await bicloudAxios.put('/user/updatePass', data)
    return r.data
  } catch (error) {
    console.log(error)
    return rejectWithValue(error)
  }
})

export const reportsSlice = createSlice({
  name: 'users',
  initialState: {
    list: [],
    status: null,
    msg: null,
  },
  reducers: {
    getUsers(state, action) {
      state.list = action.payload
      state.status = 'success'
    },
    unlockUser(state, action) {
      state.list = state.list.map((user) => {
        if (user.id === action.payload) return {...user, is_blocked: false}
        else return user
      })
    },
    blockUser(state, action) {
      state.list = state.list.map((user) => {
        if (user.id === action.payload) return {...user, is_blocked: true}
        else return user
      })
    },
  },
  extraReducers: {
    // [getUsers.pending]: (state, action) => {
    //   state.status = 'loading'
    // },
    // [getUsers.fulfilled]: (state, action) => {
    //   state.list = action.payload
    //   state.status = 'success'
    // },
    // [getUsers.rejected]: (state, action) => {
    //   state.status = 'failed'
    //   state.msg = {data: {detail: 'Hubo un error'}, status: 'error'}
    // },
    [createUser.pending]: (state, action) => {
      state.status = 'loading'
    },
    [createUser.fulfilled]: (state, action) => {
      state.msg = {content: 'El usuario fue creado con éxito', severity: 'success'}
      state.status = 'success'
    },
    [createUser.rejected]: (state, action) => {
      state.msg = {content: 'Hubo un error al crear el usuario', severity: 'danger'}
      state.status = 'failed'
    },
    [editUser.fulfilled]: (state, action) => {
      state.msg = {content: 'El usuario fue modificado con éxito', severity: 'success'}
      state.status = 'success'
    },
    [editUser.rejected]: (state, action) => {
      state.msg = {content: 'Hubo un error al modificar el usuario', severity: 'danger'}
      state.status = 'failed'
    },
    [editPersonalData.fulfilled]: (state, action) => {
      state.msg = {content: 'Los datos fueron modificados con éxito', severity: 'success'}
      state.status = 'success'
    },
    [editPersonalData.rejected]: (state, action) => {
      state.msg = {content: 'Hubo un error al modificar los datos', severity: 'danger'}
      state.status = 'failed'
    },
    [updatePassExpired.fulfilled]: (state, action) => {
      state.msg = null
      state.status = 'success'
    },
    [updatePassExpired.rejected]: (state, action) => {
      state.msg = {content: 'Hubo un error al modificar los datos', severity: 'danger'}
      state.status = 'failed'
    },
    [updatePass.pending]: (state, action) => {
      // state.status = 'loading'
    },
    [updatePass.fulfilled]: (state, action) => {
      // state.status = 'success'
    },
    [updatePass.rejected]: (state, action) => {
      // state.status = 'failed'
    },
  },
})

export const {unlockUser, blockUser, getUsers} = reportsSlice.actions
export default reportsSlice.reducer
